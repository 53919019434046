import React from 'react';
import { Img } from 'components/base';
import Meta from 'components/Meta';
import Language from 'constants/Language';
import { curlyQuotes } from 'utils/text';
import { Link } from './Link';
import { RouteMap } from 'constants/RouteMap';
import { TabletLogo } from './icons';

interface Props {
  forceNoCache?: boolean;
}

const PageNotFound: React.FC<Props> = (props) => {
  const { forceNoCache } = props;

  return (
    <>
      <Meta is404={true} forceNoCache={!!forceNoCache} />
      <div className="PageNotFound content-height-with-footer content-padding-x bg-color-off-white">
        <div className="PageNotFound__container mxauto py2 flex flex-col items-center">
          <Link
            to={RouteMap.HOME.path}
            aria-label={Language.t('Global.homeButtonAriaLabel')}
            className={'Nav__logo events-all trigger-LogoClick-MainLogo mb3'}
          >
            <TabletLogo className="Nav__logo-icon" color={'black'} />
          </Link>
          <h1 className="PageNotFound__title itc-cushing font-300 text-center">
            {Language.t('PageNotFound.title')}
          </h1>
          <p className="text-section-details-sm graebenbach font-400 mt1_5 text-center">
            {Language.t('PageNotFound.description')}
          </p>
          <Link to={'/'} aria-label={Language.t('Global.homeButtonAriaLabel')}>
            <Img
              alt={Language.t('PageNotFound.description')}
              src="/assets/images/error_feifer.png"
              className="PageNotFound__image w100 mt2"
            />
          </Link>
          <span className="PageNotFound__credit color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
            {curlyQuotes(Language.t('PageNotFound.imageCredit'))}
          </span>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
