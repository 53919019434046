import { GetStaticProps } from 'next';
import PageNotFound from 'components/PageNotFound';

interface Props {}

const Page: React.FC<Props> = () => {
  return <PageNotFound />;
};

export default Page;

export const getStaticProps: GetStaticProps<Props> = async () => {
  return {
    props: {},
  };
};
